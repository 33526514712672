import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
  faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons';

import { social } from './SocialMedia.module.scss';

const icons = {
  facebook: faFacebookSquare,
  instagram: faInstagramSquare,
  twitter: faTwitterSquare,
  youtube: faYoutubeSquare
};

const populateIcons = (socials) => {
  const iconList = [];
  for (const obj of socials) {
    if (obj.url.trim()) {
      iconList.push(
        <a className="py-0 px-4" href={obj.url.trim()} title={obj.icon} key={iconList.length}>
          <motion.span whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }} className="icon is-medium">
            <FontAwesomeIcon className={social} icon={icons[obj.icon]} size="2x" />
          </motion.span>
        </a>
      );
    }
  }

  return iconList;
};

export const PureSocialMedia = ({ socials }) => (
  <div className="container is-fluid has-text-centered">
    <h2 className="title is-4">Follow Us</h2>
    <div className="container is-mobile">{populateIcons(socials)}</div>
  </div>
);

PureSocialMedia.propTypes = {
  socials: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOf(['facebook', 'instagram', 'twitter', 'youtube']).isRequired,
      url: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

const SocialMedia = () => {
  const { socialMedia } = useStaticQuery(
    graphql`
      query socialMedia {
        markdownRemark(frontmatter: { templateKey: { eq: "HomePage" } }) {
          frontmatter {
            socialMedia {
              icon
              url
            }
          }
        }
      }
    `
  ).markdownRemark.frontmatter;

  return <PureSocialMedia socials={socialMedia} />;
};

export default SocialMedia;
