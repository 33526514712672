import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import AnimatedButtonWrapper from './utils/AnimatedButtonWrapper';

const ContactModal = ({ isOpen, toggle }) => {
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) toggle();
  };

  return (
    <section className={`modal ${isOpen && 'is-active'}`}>
      <div
        className="modal-background"
        onClick={toggle}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex="0"
        aria-label="close"
      />
      <div className="modal-card">
        <header className="modal-card-head">
          <h1 className="modal-card-title">Contact Us</h1>
        </header>
        <div className="modal-card-body">
          <form id="contact-form" name="contact-form" method="POST" data-netlify="true" netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact-form" />
            <input type="hidden" name="bot-field" />
            <div className="field">
              <label className="label" htmlFor="contactEmail">
                Email
              </label>
              <div className="control has-icons-left">
                <input
                  id="contactEmail"
                  className="input"
                  type="email"
                  placeholder="youremail@email.com"
                  name="email"
                  required
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="contactMessage">
                Message
              </label>
              <div className="control">
                <textarea id="contactMessage" className="textarea" name="message" placeholder="Message" required />
              </div>
            </div>
          </form>
        </div>
        <footer className="modal-card-foot">
          <div className="field is-grouped">
            <div className="control">
              <AnimatedButtonWrapper className="button background-secondary" type="submit" form="contact-form">
                Send Message
              </AnimatedButtonWrapper>
            </div>
            <div className="control">
              <AnimatedButtonWrapper className="button is-danger" type="button" onClick={toggle}>
                Cancel
              </AnimatedButtonWrapper>
            </div>
          </div>
        </footer>
      </div>
    </section>
  );
};
ContactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default ContactModal;
