import React from 'react';

import ContactModal from './ContactModal';
import SocialMedia from './SocialMedia/SocialMedia';
import Legal from './Legal';
import useToggle from '../hooks/useToggle';
import AnimatedButtonWrapper from './utils/AnimatedButtonWrapper';

const Footer = () => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <footer className="footer is-fullwidth py-5 background-primary">
      <div className="container">
        <div className="columns is-vcentered pb-5">
          <div className="column is-fluid has-text-centered">
            <AnimatedButtonWrapper className="button has-text-weight-semibold" type="button" onClick={toggle}>
              Contact Us
            </AnimatedButtonWrapper>
            <ContactModal isOpen={isOpen} toggle={toggle} />
          </div>
          <div className="column">
            <SocialMedia />
          </div>
        </div>
        <Legal />
      </div>
    </footer>
  );
};

export default Footer;
