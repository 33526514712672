import React, { useContext } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faDollarSign } from '@fortawesome/free-solid-svg-icons';

import { logo, badge, badgeWrapper, mobile } from './Navbar.module.scss';
import { CartContext } from '../CartProvider';
import useToggle from '../../hooks/useToggle';
import { ReactComponent as LogoSvg } from '../../assets/svg/logo.svg';

export const PureNavbar = ({ isHamburgerOpen, toggleMenu, state }) => {
  const { cartInfo, currency, setCurrency } = state;

  const handleMenuKeyDown = (e) => {
    if (e.keyCode === 13) toggleMenu();
  };

  let individualItems = 0;

  for (const productId in cartInfo) {
    if ({}.hasOwnProperty.call(cartInfo, productId)) {
      individualItems += cartInfo[productId].quantity;
    }
  }

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
    localStorage.setItem('currency', JSON.stringify(e.target.value));
  };

  return (
    <motion.nav
      initial={{ opacity: 0.65 }}
      whileHover={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className={`navbar is-fixed-top ${mobile}`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container is-fluid">
        <div className="navbar-brand">
          <Link className="navbar-item" aria-label="brand logo" to="/">
            <LogoSvg className={logo} />
          </Link>
          <div
            className={`navbar-burger ${isHamburgerOpen && 'is-active'}`}
            role="button"
            tabIndex={0}
            aria-label="menu"
            aria-expanded={isHamburgerOpen ? 'true' : 'false'}
            onClick={toggleMenu}
            onKeyDown={handleMenuKeyDown}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={`navbar-menu ${isHamburgerOpen && 'is-active'}`}>
          <div className="navbar-end has-text-centered">
            <Link className="navbar-item" to="/">
              Home
            </Link>
            <Link className="navbar-item" to="/blog">
              Blog
            </Link>
            <Link className="navbar-item" to="/guides">
              Guides
            </Link>
            <Link className="navbar-item" to="/products">
              Products
            </Link>
          </div>
          <div className="navbar-end has-text-centered">
            <Link className="navbar-item" aria-label="shopping cart" to="/cart">
              <span className={`icon is-medium ${badgeWrapper}`}>
                <span className={`${badge}`} data-badge={individualItems > 99 ? `99+` : individualItems}>
                  <FontAwesomeIcon icon={faShoppingCart} size="lg" />
                </span>
              </span>
            </Link>
            <div className="navbar-item">
              <span className="icon is-small">
                <FontAwesomeIcon icon={faDollarSign} />
              </span>
              <div className="select is-small">
                <select value={currency} onChange={handleCurrencyChange} aria-label="currency">
                  <option value="cad">CAD</option>
                  <option value="usd">USD</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.nav>
  );
};

PureNavbar.propTypes = {
  isHamburgerOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  state: PropTypes.shape({
    cartInfo: PropTypes.shape({
      productId: PropTypes.string,
      quantity: PropTypes.number,
      price: PropTypes.number,
      title: PropTypes.string,
      image: PropTypes.string,
      link: PropTypes.string
    }).isRequired,
    currency: PropTypes.string.isRequired,
    setCurrency: PropTypes.func.isRequired
  }).isRequired
};

const Navbar = () => {
  const [isHamburgerOpen, toggleMenu] = useToggle(false);
  const { cartInfo, currency, setCurrency } = useContext(CartContext);

  return (
    <PureNavbar isHamburgerOpen={isHamburgerOpen} toggleMenu={toggleMenu} state={{ cartInfo, currency, setCurrency }} />
  );
};

export default Navbar;
