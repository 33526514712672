import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, useStaticQuery } from 'gatsby';
import dayjs from 'dayjs';

// pure component
export const PureLegal = ({ articles }) => (
  <article className="level has-text-centered">
    {articles.map(({ node }) => (
      <article key={node.id} className="level-item">
        <Link className="title is-6" to={node.fields.slug}>
          {node.frontmatter.title}
        </Link>
      </article>
    ))}
    <article className="level-item">
      <p className="title is-6">{`© ${dayjs().year()} ela`}</p>
    </article>
  </article>
);

PureLegal.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        fields: PropTypes.shape({
          slug: PropTypes.string.isRequired
        }).isRequired,
        frontmatter: PropTypes.shape({
          title: PropTypes.string.isRequired
        }).isRequired
      }).isRequired
    }).isRequired
  ).isRequired
};

const Legal = () => {
  const { edges } = useStaticQuery(
    graphql`
      query legalPageQuery {
        allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "LegalPage" } } }) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `
  ).allMarkdownRemark;

  return <PureLegal articles={edges} />;
};

export default Legal;
