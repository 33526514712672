import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const AnimatedButtonWrapper = ({ children, ...attrs }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    transition={{ duration: 0.2, ease: 'easeInOut' }}
    {...attrs}
  >
    {children}
  </motion.button>
);

AnimatedButtonWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default AnimatedButtonWrapper;
