import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { graphql, useStaticQuery } from 'gatsby';
import { spacing } from './Wrapper.module.scss';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer';

const Wrapper = ({ children }) => {
  const { site } = useStaticQuery(graphql`
    query siteTitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className={spacing}>
      <Helmet title={site.siteMetadata.title} defer={false} htmlAttributes={{ lang: 'en' }} />
      <header>
        <Navbar />
      </header>
      {children}
      <Footer />
    </div>
  );
};

Wrapper.defaultProps = {
  children: null
};

Wrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default Wrapper;
